import { ref, watch, computed } from '@vue/composition-api'
import { getTerminalFacility } from '@api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useSelectOptions from '@core/utils/useSelectOptions'

export default function useTerminalsList() {
  const { t } = useUtils()
  const { hasActionPermission, hasPermission } = usePermissions()
  const { configFacility } = useSelectOptions()

  const dataListTable = ref([])
  const tableColumns = computed(() => [
    { text: t('terminals.name').toUpperCase(), value: 'name', show: true },
    { text: t('terminals.reference_reader').toUpperCase(), value: 'reader_label', show: true },
    { text: t('terminals.serial').toUpperCase(), value: 'reader_serial', show: true },
    { text: t('terminals.description').toUpperCase(), value: 'description', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(49, 51, 52),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))
  const totalDataListTable = ref(0)

  const searchQuery = ref('')
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const filters = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)

  // fetch data
  const fetchTerminals = async () => {
    const response = await getTerminalFacility(configFacility.value)

    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    filters.value = filterOption

    const filteredData = response.data.filter(
      item =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        item.name.toLowerCase().includes(searchQuery.value) || item.description.toLowerCase().includes(searchQuery.value) || item.stripe_reader.toLowerCase().includes(searchQuery.value),
    )

    dataListTable.value = filteredData
    totalDataListTable.value = filteredData.length
    loading.value = false
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
    }
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchTerminals()
    }, 1000)
  }

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await fetchTerminals()
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'

    return 'primary'
  }

  const resolveStatusText = status => {
    if (status === 'A') return t('status.active')
    if (status === 'I') return t('status.inactive')

    return t('status.inactive')
  }

  return {
    dataListTable,
    computedTableColumns,
    totalDataListTable,
    searchQuery,
    loading,
    options,
    filters,

    fetchTerminals,
    resolveStatusVariant,
    resolveStatusText,
    hasPermission,
    setFilters,
    setOptions,
  }
}
